<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th>Material Type</th>
          <th>Containers Shipped</th>
          <th>Total Gross</th>
          <th>Total Tare</th>
          <th>Total Net</th>
          <th>Intake Units</th>
          <th v-if="report.show_avg_lbs_per_cat">
            Avg. Lbs/Cat
          </th>
          <th v-if="report.show_avg_catalyst_lbs_cat">
            Avg. Catalyst Lbs/Cat
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in report.lot_summary"
          :key="index"
        >
          <td>
            {{ row.material_type }}
          </td>
          <td>
            {{ row.containers_shipped | toFixed(0) }}
          </td>
          <td>
            {{ row.total_gross | toFixed(3) | addCommas }} lbs
          </td>
          <td>
            {{ row.total_tare | toFixed(3) | addCommas }} lbs
          </td>
          <td>
            {{ row.total_net | toFixed(3) | addCommas }} lbs
          </td>
          <td>
            {{ row.processed_units | toFixed(0) | addCommas }}
          </td>
          <td v-if="report.show_avg_lbs_per_cat">
            {{ row.avg_lbs_per_cat | toFixed(2) }} lbs/cat
          </td>
          <td v-if="report.show_avg_catalyst_lbs_cat">
            {{ row.avg_catalyst_lbs_cat | toFixed(2) }} lbs/cat
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>

export default {
  name: 'FinalReportShipment',
  data: () => ({
    weightObj: {
      text: 'lbs',
      fontSize: '8px'
    }
  }),
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
