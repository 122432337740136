<template>
  <div>
    <v-simple-table dense>
      <tbody>
        <tr>
          <td>
            Total Investment
          </td>
          <td>Total Revenue</td>
          <td>Total Profit</td>
          <!-- <td>Gross Margin</td> -->
          <td>Profit Percentage</td>
        </tr>
        <tr>
          <td><dollar-sign />{{ report.total_investment | toFixed(2) | addCommas }} {{ report.currency }}</td>
          <td><dollar-sign />{{ report.total_revenue | toFixed(2) | addCommas }}</td>
          <td><dollar-sign />{{ report.total_profit | toFixed(2) | addCommas }}</td>
          <td>{{ report.gross_margin | decimalToPercent(2) }}</td>
        </tr>
        <tr>
          <td>Average Cost Per Cat</td>
          <td>Average Return Per Cat</td>
          <td>Average Profit Per Cat</td>
          <td>Mark-Up Percentage</td>
        </tr>
        <tr>
          <td><dollar-sign />{{ report.avg_cost_per_cat | toFixed(2) | addCommas }}</td>
          <td><dollar-sign />{{ report.avg_return_per_cat | toFixed(2) | addCommas }}</td>
          <td><dollar-sign />{{ report.avg_profit_per_cat | toFixed(2) | addCommas }}</td>
          <td>{{ report.markup_margin | decimalToPercent(2) }}</td>
        </tr>
        <tr>
          <td>Average Cost Per Pound</td>
          <td>Average Return Per Pound</td>
          <td>Average Profit Per Pound</td>
          <td />
        </tr>
        <tr>
          <td><dollar-sign />{{ report.avg_cost_per_lb | toFixed(2) | addCommas }}</td>
          <td><dollar-sign />{{ report.avg_return_per_lb | toFixed(2) | addCommas }}</td>
          <td><dollar-sign />{{ report.avg_profit_per_lb | toFixed(2) | addCommas }}</td>
          <td />
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>

export default {
  name: 'FinalReportProfit',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
