<template>
  <div>
    <url-breadcrumbs
      v-if="breadcrumbs"
      :items="breadcrumbs"
    />
    <v-card
      v-if="settlement"
      class="pa-2 mx-auto"
      flat
      max-width="1000px"
    >
      <v-row>
        <v-col
          class="text-left"
          cols="3"
        >
          <v-img
            :src="config.logo"
            :alt="config.name"
            height="100px"
            contain
          />
        </v-col>
        <v-col
          class="text-center mt-6"
          cols="6"
        >
          <h2>
            {{ settlement.yard.name }}
          </h2>
        </v-col>
        <v-col
          class="text-right mt-4"
          cols="3"
        >
          <b>
            Date Shipped: {{ settlement.shipped_date | formatDate }}
            <br>
            Lot: {{ settlement.lot_name }}
          </b>
        </v-col>
      </v-row>
      <div>
        <span class="font-weight-bold">
          Catalyst Shipped
        </span>
      </div>
      <div
        v-if="settlement.show_averages"
        class="d-flex justify-space-between"
      >
        <div>
          <span class="font-weight-bold body-2">Converters Processed:</span>
          {{ settlement.converters_processed | addCommas }}
        </div>
        <div>
          <span class="font-weight-bold body-2">
            Average per CAT:
          </span>
          ${{ settlement.converter_average_price | addCommas }}
        </div>
        <div>
          <span class="font-weight-bold body-2">
            Average per LB:
          </span>
          ${{ settlement.average_per_lb | addCommas }}
        </div>
      </div>
      <v-row>
        <v-col
          class="text-left"
          cols="6"
        >
          <div v-if="!settlement.show_averages">
            <strong>Converters Processed:</strong>
            {{ settlement.converters_processed | addCommas }}
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-simple-table dense>
            <thead>
              <tr>
                <th />
                <th>
                  Mixed Catalyst
                </th>
                <th>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Weight</b></td>
                <td>{{ settlement.received_lbs_total | addCommas }}</td>
                <td>{{ settlement.received_lbs_total | addCommas }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <h5>
            Assay Results
          </h5>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col
          cols="12"
        >
          <v-simple-table
            dense
          >
            <thead>
              <tr>
                <th>
                  Metal
                </th>
                <th>
                  Hedge ID
                </th>
                <th>
                  Contained Toz
                </th>
                <th>
                  Contract Return %
                </th>
                <th>
                  Returnable Toz
                </th>
                <th>
                  Hedge Value
                </th>
                <th>
                  Total Value
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="m in ['pt', 'pd', 'rh']">
                <template v-for="(metal, index) in getMetalRows(m)">
                  <tr
                    :key="index + '-' + m"
                  >
                    <td><span v-if="index === 0">{{ m | capitalize }}</span></td>
                    <td>{{ metal.hedge_id }}</td>
                    <td />
                    <td />
                    <td>{{ Number(metal[m + '_client_returnable']).toFixed(3) }}</td>
                    <td>{{ metal[m + '_mk'] | currencyUSD }}</td>
                    <td>{{ metal[m + '_value'] | currencyUSD }}</td>
                  </tr>
                </template>
                <tr :key="m + '-totals'">
                  <td />
                  <td />
                  <td>{{ metalTotals[m].toFixed(3) }}</td>
                  <td>{{ metalTotals[m + '_return_rate'] | appendPercent }}</td>
                  <td>{{ metalTotals[m + '_client'].toFixed(3) }}</td>
                  <td />
                  <td>{{ metalTotals[m + '_value'] | currencyUSD }}</td>
                </tr>
                <tr
                  v-if="m !== 'rh'"
                  :key="m + 'spacer'"
                >
                  <td colspan="8" />
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="purchasedOutright.length"
          cols="12"
        >
          <div>
            <h5>
              Units Purchased Outright
            </h5>
            <v-divider />
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>
                    Quantity
                  </th>
                  <th>
                    Unit Price
                  </th>
                  <th>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="u in purchasedOutright"
                  :key="u.id"
                >
                  <td>
                    {{ u.material_type }}
                  </td>
                  <td>
                    {{ u.quantity }}
                  </td>
                  <td>
                    {{ u.unit_price | currencyUSD }}
                  </td>
                  <td>
                    {{ u.total_price | currencyUSD }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    class="text-center"
                    colspan="4"
                  >
                    Average Unit Value
                    {{ purchasedOutrightFooter.average | currencyUSD }}
                  </td>
                </tr>
              </tfoot>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          offset-sm="6"
          offset-md="8"
        >
          <v-simple-table dense>
            <tbody>
              <tr>
                <td
                  class="font-weight-bold text-lg-body-1 text-left"
                >
                  Gross Value
                </td>
                <td>
                  {{ settlement.gross_value | currencyUSD }}
                </td>
              </tr>
              <tr
                v-for="row in fees"
                :key="row.title"
              >
                <td>
                  {{ row.title }}
                </td>
                <td
                  :class="row.class"
                >
                  ({{ row.value | addCommas | dollarSign }})
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold text-lg-body-1 text-left"
                >
                  Final Settlement
                </td>
                <td>
                  {{ settlement.net_value | currencyUSD }}
                </td>
              </tr>
              <tr v-if="settlement.advances">
                <td>
                  Advance Payment
                </td>
                <td>
                  ({{ settlement.advances | currencyUSD }})
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold text-lg-body-1 text-left"
                >
                  Total Due
                </td>
                <td>
                  {{ settlement.amount_due | currencyUSD }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col v-if="settlement.notes">
          <v-subheader>
            Notes: {{ settlement.notes }}
          </v-subheader>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/../izzi_config'

export default {
  name: 'BrokeredSettlementPrint',
  data: () => ({
    config,
    breadcrumbs: [],
    settlement: null
  }),
  computed: {
    purchasedOutright () {
      return this.settlement.purchased_outright.filter(po => +po.quantity !== 0)
    },
    /**
     * Populated the purchased outright footer rows
     * @returns {{average: number, total: number, quantity: number}}
     */
    purchasedOutrightFooter () {
      let t = { quantity: 0, total: 0, average: 0 }
      this.settlement.purchased_outright.forEach(po => {
        t.quantity += +po.quantity
        t.total += +po.total_price
      })
      if (t.quantity !== 0) t.average = t.total / t.quantity
      return t
    },
    showHedgeId () {
      return this.settlement.metals_details.length > 1
    },
    /**
     * Returns total values for each metal.
     * @returns {{rh_client: number, pt: number, pd: number, rh_return_rate: number, rh: number, pt_return_rate: number, pt_value: number, pt_client: number, pd_value: number, pd_client: number, pd_return_rate: number, rh_value: number}}
     */
    metalTotals () {
      const metals = ['pt', 'pd', 'rh']
      let accum = {}

      for (const key of metals) {
        const rows = this.getMetalRows(key)
        accum[key] = 0
        accum[key + '_client'] = 0
        accum[key + '_return_rate'] = 0
        accum[key + '_value'] = 0
        rows.forEach(metal => {
          accum[key] += Number(metal[key + '_returnable'])
          accum[key + '_client'] += Number(metal[key + '_client_returnable'])
          accum[key + '_return_rate'] += Number(metal[key + '_return_rate'])
          accum[key + '_value'] += Number(metal[key + '_value'])
        })
        if (rows.length) {
          accum[key + '_return_rate'] = (accum[key + '_return_rate'] / rows.length).toFixed(2)
        }
      }
      return accum
    },
    /* Conditionally showing total Totals with exception of Gross/Net/Due */
    fees () {
      const s = this.settlement
      let row = []
      if (Number(s.treatment_fee) !== 0) row.push({ title: 'Treatment Fee', value: s.treatment_fee })
      if (Number(s.small_lot_fee) !== 0) row.push({ title: 'Handling fee', value: s.small_lot_fee })
      if (Number(s.finance_charge) !== 0) row.push({ title: 'Finance Charge', value: s.finance_charge })
      if (Number(s.freight_charge) !== 0) row.push({ title: 'Freight Charge', value: s.freight_charge })
      if (Number(s.unpaid_units) !== 0) row.push({ title: 'AM/DPF/Foil Value', value: s.unpaid_units })
      for (const fee of this.settlement.additional_fees) {
        if (Number(fee.other_fee_value) !== 0) row.push({ title: fee.other_fee_name, value: fee.other_fee_value })
      }
      // ? Shredding
      // ? brokerage
      // row.push({ title: 'Net Value', value: s.net_value, class: 'font-weight-bold' })
      // if (Number(s.advances) !== 0) row.push({ title: 'Less Advance', value: s.advances, class: 'font-italic' })
      // row.push({ title: 'Total Due', value: s.amount_due, class: 'font-weight-bold' })
      return row
    }
  },
  methods: {
    getMetalRows (key) {
      return this.settlement.metals_details.filter((row) => {
        return row[key + '_returnable'] > 0
      })
    }
  },
  mounted () {
    axios.get(`/api/brokered_settlements/${this.$route.params.id}/`).then(bs => {
      this.settlement = bs.data
      this.breadcrumbs = [
        ['Lot List', { name: 'lot-list' }],
        ['Lot #' + bs.data.lot_id, { name: 'lot', params: { id: bs.data.lot_id } }],
        ['Brokered Settlement #' + bs.data.id, { name: 'brokered-settlement', params: { id: bs.data.id } }],
        ['Print', { name: 'print-brokered-settlement', params: { id: bs.data.id } }]
      ]
    })
  }
}
</script>

<style scoped>
th, td {
  text-align: right !important;
}
</style>
