<template>
  <unauthorized
    :authorized="$store.getters.can('view-profit-estimation')"
    message="You are not authorized to view Lots Reports"
  >
    <v-container v-if="!selectedSnapshot.snapshot">
      <v-skeleton-loader type="heading" />
    </v-container>
    <v-container
      fluid
      v-else
    >
      <base-title title="Lot Profit Estimation">
        <template v-slot:subtitle>
          Report created on {{ selectedSnapshot.created_at }}
        </template>
      </base-title>
      <v-card
        class="mb-8 mx-auto"
        flat
        max-width="1000px"
      >
        <v-col
          class="text-center"
          cols="6"
          offset="3"
        >
          <v-img
            :src="config.logo"
            :alt="config.name"
            height="100px"
            contain
          />
        </v-col>
        <v-card-text class="d-print-none">
          Attached Lots
          <v-list>
            <v-list-item
              v-for="lot in selectedSnapshot.snapshot.lot_list"
              :key="lot.id"
            >
              <router-link-id
                :id="lot.id"
                name="lot"
              >
                {{ lot.name }}
              </router-link-id>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col
              cols="3"
            >
              <v-select
                v-model="selectedSnapshot"
                :items="allSnapshots"
                label="Report Versions"
                item-text="created_at"
                return-object
                dense
                @input="setSelectedSnapshot"
              />
            </v-col>
            <v-spacer />
            <v-col
              class="text-right"
            >
              <span>
                <v-btn
                  color="accent"
                  @click="emailDialog = !emailDialog"
                  small
                >
                  Send Email
                </v-btn>
              </span>
              <edit-dialog
                v-model="emailDialog"
                headline-text="Send emails"
                accept-text="send"
                @accept="sendEmails"
              >
                Are you sure you would like to send an email of the Profit Estimation?
              </edit-dialog>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        class="pa-2 mx-auto"
        flat
        max-width="1000px"
      >
        <profit-estimation-overview
          class="mb-10"
          :report="selectedSnapshot.snapshot"
        />
        <h3>Lot Shipment Summary</h3>
        <hr>
        <final-report-shipment
          class="mb-10"
          :report="selectedSnapshot.snapshot"
        />
        <h4>Metal Summary</h4>
        <final-report-summary-metals
          class="mb-10"
          :report="selectedSnapshot.snapshot"
        />
        <final-report-values
          class="mb-10"
          :report="selectedSnapshot.snapshot"
        />
        <h3>
          Metals Allocations
          <info-tooltip>
            Allocations show are Pre & Final until a lot is shipped in which case Historic are shown.
          </info-tooltip>
        </h3>
        <hr>
        <detailed-report-allocations
          class="mb-10"
          :report="selectedSnapshot.snapshot"
        />
        <h3>Profit Summary</h3>
        <hr>
        <final-report-profit
          class="mb-10"
          :report="selectedSnapshot.snapshot"
        />
        <!--there are no notes for lot finance-->
        <profit-estimation-edit
          v-if="selectedSnapshot.snapshot"
          v-model="edit"
          :report="selectedSnapshot.snapshot"
          :profit-estimation="selectedSnapshot.snapshot.profit_estimation"
          @report-updated="reportUpdate"
        />
        <h3>Notes</h3>
        <hr>
        <p
          v-if="selectedSnapshot.snapshot.notes"
          class="pa-4 pb-10"
        >
          {{ selectedSnapshot.snapshot.notes }}
        </p>
        <p v-else>
          <small>
            <i>Left Blank</i>
          </small>
        </p>
      </v-card>
      <v-btn
        class="d-print-none"
        color="accent"
        fixed
        fab
        bottom
        right
        @click="edit = !edit"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-container>
  </unauthorized>
</template>

<script>
import profitEstimationReport from '@/store/generics/genericProfitEstimationReport'
import profitEstimation from '@/store/generics/genericProfitEstimation'
import ProfitEstimationOverview from './components/ProfitEstimationOverview'
import FinalReportShipment from './components/FinalReportShipment'
import FinalReportSummaryMetals from './components/FinalReportSummaryMetals'
import FinalReportValues from './components/FinalReportValues'
import FinalReportProfit from './components/FinalReportProfit'
import DetailedReportAllocations from '@/views/FinanceReport/components/DetailedReportAllocations'
import ProfitEstimationEdit from '@/views/FinanceReport/components/ProfitEstimationEdit'
import axios from 'axios'
import config from '@/../izzi_config'

export default {
  name: 'LotProfitEstimation',
  components: {
    ProfitEstimationEdit,
    DetailedReportAllocations,
    FinalReportValues,
    FinalReportProfit,
    FinalReportSummaryMetals,
    FinalReportShipment,
    ProfitEstimationOverview
  },
  data: () => ({
    config,
    loading: false,
    edit: false,
    emailDialog: false,
    allSnapshots: [],
    selectedSnapshot: {}
  }),
  methods: {
    async reportUpdate (payload) {
      this.loading = true
      const id = this.$route.params.id
      if (id !== null) {
        await this.$store.dispatch('profitEstimation/patchProfitEstimation', { id, payload })
        await this.$store.dispatch('profitEstimationReport/fetchReport', this.$route.params.id)
        const response = await axios.post(`/api/profit_estimation/${this.$route.params.id}/new_report/`)
        if (response.status === 200) {
          this.$store.commit('setSnackbarSuccess', 'Profit Estimation Report Updated')
          this.setupSnapshots()
        }
      }
      this.loading = false
    },
    sendEmails () {
      let snapshotID = { id: this.selectedSnapshot.id }
      axios.post(`/api/profit_estimation/${this.$route.params.id}/send_emails/`, snapshotID)
    },
    setSelectedSnapshot (report) {
      this.selectedSnapshot = report
    },
    getParsedSnapshot (json) {
      return JSON.parse(json)
    },
    getFormattedDate (datetime) {
      // including time as v-select will eliminate duplicate values. ie reports generated on the same date, if the display name was only the date
      const date = new Date(datetime)
      const formattedDate = [
        date.getMonth() + 1,
        date.getDate(),
        date.getFullYear()
      ].join('/')
      const formattedTime = [
        date.getHours(),
        String(date.getMinutes()).padStart(2, '0'),
        String(date.getSeconds()).padStart(2, '0')
      ].join(':')
      return formattedDate + ' ' + formattedTime
    },
    async setupSnapshots () {
      const snapshots = await axios.get(`/api/profit_estimation/${this.$route.params.id}/reports/`)
      this.allSnapshots = snapshots.data.map(report => {
        return {
          id: report.id,
          snapshot: this.getParsedSnapshot(report.snapshot),
          created_at: this.getFormattedDate(report.created_at)
        }
      })
      if (this.allSnapshots.length > 0) {
        this.selectedSnapshot = this.allSnapshots[0]
      } else {
        this.$store.commit('setSnackbarError', 'No Snapshots Exist')
      }
    }
  },
  mounted () {
    this.$store.registerModule('profitEstimation', profitEstimation)
    this.$store.registerModule('profitEstimationReport', profitEstimationReport)
    this.$store.dispatch('profitEstimationReport/fetchReport', this.$route.params.id).then(() => {
      this.setupSnapshots()
    })
  },
  beforeDestroy () {
    this.$store.unregisterModule('profitEstimation')
    this.$store.unregisterModule('profitEstimationReport')
  }
}
</script>
