<template>
  <div>
    <div class="subtitle-2 text-center">
      Allocations
    </div>
    <v-data-table
      v-if="allocations.length > 0"
      multi-sort
      single-expand
      show-expand
      :expanded.sync="expanded"
      :headers="allocationTableHeaders"
      :items="allocations"
      :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
      :items-per-page="5"
    >
      <template v-slot:item.hedge="{ item }">
        {{ item.hedge | filterObject('id') }}
      </template>
      <template v-slot:item.pt="{ item }">
        <div class="pt-color">
          {{ item | filterObject('pt') | trimDecimals | addCommas | appendRtoz }}
        </div>
      </template>
      <template v-slot:item.pd="{ item }">
        <div class="pd-color">
          {{ item | filterObject('pd') | trimDecimals | addCommas | appendRtoz }}
        </div>
      </template>
      <template v-slot:item.rh="{ item }">
        <div class="rh-color">
          {{ item | filterObject('rh') | trimDecimals | addCommas | appendRtoz }}
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="allocationTableHeaders.length++">
          <v-container>
            <div class="text-center">
              <v-row v-if="$vuetify.breakpoint.mdAndUp">
                <v-col />
                <v-col>
                  <b> Pt </b>
                  @ {{ item.hedge | filterObject('pt_rate_per_toz') | trimDecimals | addCommas | dollarSign }}/toz =
                  <br>
                  {{ calculateMetal('pt', item) | trimDecimals | addCommas | dollarSign | addCommas }}
                </v-col>
                <v-col>
                  <b> Pd </b>
                  @ {{ item.hedge | filterObject('pd_rate_per_toz') | trimDecimals | addCommas | dollarSign }}/toz =
                  <br>
                  {{ calculateMetal('pd', item) | trimDecimals | addCommas | dollarSign | addCommas }}
                </v-col>
                <v-col>
                  <b> Rh </b>
                  @ {{ item.hedge | filterObject('rh_rate_per_toz') | trimDecimals | addCommas | dollarSign }}/toz =
                  <br>
                  {{ calculateMetal('rh', item) | trimDecimals | addCommas | dollarSign | addCommas }}
                </v-col>
              </v-row>
              <div v-else>
                <v-col>
                  <b> Pt </b>
                  @ {{ item.hedge | filterObject('pt_rate_per_toz') | trimDecimals | addCommas | dollarSign }}/toz =
                  {{ calculateMetal('pt', item) | trimDecimals | addCommas | dollarSign }}
                </v-col>
                <v-col>
                  <b> Pd </b>
                  @ {{ item.hedge | filterObject('pd_rate_per_toz') | trimDecimals | addCommas | dollarSign }}/toz =
                  {{ calculateMetal('pd', item) | trimDecimals | addCommas | dollarSign }}
                </v-col>
                <v-col>
                  <b> Rh </b>
                  @ {{ item.hedge | filterObject('rh_rate_per_toz') | trimDecimals | addCommas | dollarSign }}/toz =
                  {{ calculateMetal('rh', item) | trimDecimals | addCommas | dollarSign }}
                </v-col>
              </div>
              <br>
              <b> Total: </b> {{ calculateTotalRoz(item) | addCommas | trimDecimals | dollarSign }}
              <br>
              <b> Created: </b> {{ item.hedge | filterObject('created_at') | formatDate }}
              <b> Expires: </b> {{ item.hedge | filterObject('expires_on') | formatDate }}
              <br>
              <b> Notes: </b> {{ item.hedge | filterObject('memo') | filterNA }}
            </div>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <div
      v-else
      class="text-center pb-4 font-weight-thin"
    >
      No Allocations Found
    </div>
    <hedge-allocation-dialog
      v-if="canAddAllocation()"
      :unallocated-metals="allUnallocatedMetals()"
      :lot="lot.id"
      :hedges="hedges"
      @addedAllocation="$emit('addedAllocation')"
    />
  </div>
</template>

<script>
import HedgeAllocationDialog from './HedgeAllocationDialog'

export default {
  name: 'Allocations',
  components: {
    HedgeAllocationDialog
  },
  props: {
    allocations: { type: Array, required: true },
    finalToz: { type: Object, required: true },
    hedges: { type: Array, required: true },
    lot: { type: Object, required: true }
  },
  data: () => ({
    expanded: [],
    allocationTableHeaders: [
      { text: 'Hedge', sortable: true, value: 'hedge' },
      { text: 'Platinum', sortable: true, value: 'pt' },
      { text: 'Palladium', sortable: true, value: 'pd' },
      { text: 'Rhodium', sortable: true, value: 'rh' }
    ]
  }),
  methods: {
    // Returns total dollar amount per given metal
    calculateMetal (metal, item) {
      let result = 0
      if (metal === 'pt') {
        result = (item.pt * item.hedge.pt_rate_per_toz)
      } else if (metal === 'rh') {
        result = (item.rh * item.hedge.rh_rate_per_toz)
      } else {
        result = (item.pd * item.hedge.pd_rate_per_toz)
      }
      return result.toFixed(2)
    },
    // Returns the total hedge dollar amount for returnable
    calculateTotalRoz (item) {
      let totalPt = (item.pt * item.hedge.pt_rate_per_toz)
      let totalPd = (item.pd * item.hedge.pd_rate_per_toz)
      let totalRh = (item.rh * item.hedge.rh_rate_per_toz)
      let totalAmount = (totalPt + totalRh + totalPd)
      return totalAmount.toFixed(2)
    },
    /**
     * Is the user able to add an allocation
     * Are there any ounces that need to be hedged
     * @return {Boolean} canAddAllocation
     */
    canAddAllocation () {
      const unallocated = Object.values(this.allUnallocatedMetals()).reduce((accu, val) => {
        accu += parseFloat(val)
        return accu
      }, 0)
      return unallocated > 0
    },
    /**
     * Get the amount of a metal left unhedged
     * @param {String} metal
     */
    unallocatedMetal (metal) {
      metal = metal.toLowerCase()
      const ouncesAllocated = this.allocations.filter(a => a.status !== 'HIS').reduce((accu, alloc) => {
        if (metal === 'pt') {
          accu += parseFloat(alloc.pt)
        } else if (metal === 'pd') {
          accu += parseFloat(alloc.pd)
        } else {
          accu += parseFloat(alloc.rh)
        }
        return accu
      }, 0)
      return (this.finalToz[metal] - ouncesAllocated)
    },
    /**
     * Returns all unallocated metals in an object
     */
    allUnallocatedMetals () {
      return {
        pt: this.unallocatedMetal('pt'),
        pd: this.unallocatedMetal('pd'),
        rh: this.unallocatedMetal('rh')
      }
    }
  }
}
</script>
