<template>
  <unauthorized
    :authorized="$store.getters.can('view-smelter-shipment-history-report')"
    message="Unauthorized to view smelter shipment history reports"
  >
    <v-row>
      <v-col
        cols="12"
        xl="10"
        offset-xl="1"
      >
        <h1 class="title">
          Smelter Shipment History Reports
        </h1>
        <p class="subtitle-1">
          Filter lots by various criteria to view historic smelter material shipment data
        </p>
        <v-expansion-panels
          v-model="panel"
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="text-subtitle-1">
                Report Generation Controls
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-select
                    label="smelters"
                    v-model="selectedSmelters"
                    :items="smelters"
                    item-value="name"
                    item-text="name"
                    multiple
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-select
                    label="lot status"
                    v-model="selectedLotStatuses"
                    :items="lotStatuses"
                    item-value="value"
                    multiple
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-select
                    label="lot designations"
                    v-model="selectedLotDesignations"
                    :items="lotTypes"
                    multiple
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-menu
                    ref="smelterReceivedAfterMenu"
                    v-model="smelterReceivedAfterMenu"
                    :close-on-content-click="false"
                    :return-value.sync="smelterReceivedAfter"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="smelterReceivedAfter"
                        label="smelter received after"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="smelterReceivedAfter"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="smelterReceivedAfterMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.smelterReceivedAfterMenu.save(smelterReceivedAfter)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-menu
                    ref="smelterReceivedBeforeMenu"
                    v-model="smelterReceivedBeforeMenu"
                    :close-on-content-click="false"
                    :return-value.sync="smelterReceivedBefore"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="smelterReceivedBefore"
                        label="smelter received before"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="smelterReceivedBefore"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="smelterReceivedBeforeMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.smelterReceivedBeforeMenu.save(smelterReceivedBefore)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-menu
                    ref="lotShippedBeforeMenu"
                    v-model="lotShippedBeforeMenu"
                    :close-on-content-click="false"
                    :return-value.sync="lotShippedBefore"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="lotShippedBefore"
                        label="lot shipped after"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="lotShippedBefore"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="lotShippedBeforeMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.lotShippedBeforeMenu.save(lotShippedBefore)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-menu
                    ref="lotShippedAfterMenu"
                    v-model="lotShippedAfterMenu"
                    :close-on-content-click="false"
                    :return-value.sync="lotShippedAfter"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="lotShippedAfter"
                        label="lot shipped before"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="lotShippedAfter"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="lotShippedAfterMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.lotShippedAfterMenu.save(lotShippedAfter)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    @click="fetchMaterialShippedToSmelterReport"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row v-if="report">
          <v-col
            cols="12"
          >
            <v-card
              class="elevation-0"
              outlined
              v-if="report !== null"
            >
              <v-card-title>
                Report
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="reportToCsv"
                >
                  download csv
                  <v-icon class="pl-2">
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                  clearable
                  single-line
                  hide-details
                />
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="report"
                  :search="search"
                  :items-per-page="-1"
                  mobile-breakpoint="0"
                >
                  <template v-slot:body.append>
                    <tr v-if="reportTotals !== null">
                      <td>
                        <strong>Totals</strong>
                      </td>
                      <td />
                      <td />
                      <td class="text-right">
                        {{ reportTotals.intake_total | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.contained_pt_toz | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.contained_pd_toz | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.contained_rh_toz | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.returnable_pt_toz | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.returnable_pd_toz | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.returnable_rh_toz | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.gross_lbs | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.net_wet_lbs | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.scrap_lbs | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.net_dry_lbs | addCommas }}
                      </td>
                      <td class="text-right">
                        {{ reportTotals.carbon_percent | addCommas }}
                      </td>
                      <td />
                    </tr>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div class="text-left">
                      <v-btn
                        icon
                        @click="removeLotFromReport(item.id)"
                        :title="`Remove &quot;${item.name}&quot; from report`"
                      >
                        <v-icon
                          small
                        >
                          mdi-cancel
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { BigNumber } from 'bignumber.js'

export default {
  name: 'SmelterShipmentHistoryReport',
  data: () => ({
    lotShippedBefore: null,
    lotShippedAfter: null,
    smelterReceivedAfter: '2023-01-01',
    smelterReceivedBefore: null,
    lotShippedBeforeMenu: false,
    lotShippedAfterMenu: false,
    smelterReceivedAfterMenu: false,
    smelterReceivedBeforeMenu: false,
    selectedSmelters: [],
    smelters: [],
    selectedLotStatuses: null,
    selectedLotDesignations: null,
    panel: 0,
    report: null,
    search: '',
    headers: [
      {
        text: 'Lot Name',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Smelter',
        value: 'shipment_summary_lot.smelter'
      },
      {
        text: 'Shipped Date',
        value: 'shipment_summary_lot.shipped_date'
      },
      {
        text: 'Intake Pcs',
        value: 'intake_total',
        align: 'right'
      },
      {
        text: 'Ct Pt toz',
        value: 'shipment_summary_lot.contained_pt_toz',
        align: 'right'
      },
      {
        text: 'Ct Pd toz',
        value: 'shipment_summary_lot.contained_pd_toz',
        align: 'right'
      },
      {
        text: 'Ct Rh toz',
        value: 'shipment_summary_lot.contained_rh_toz',
        align: 'right'
      },
      {
        text: 'Rt Pt toz',
        value: 'shipment_summary_lot.returnable_pt_toz',
        align: 'right'
      },
      {
        text: 'Rt Pd toz',
        value: 'shipment_summary_lot.returnable_pd_toz',
        align: 'right'
      },
      {
        text: 'Rt Rh toz',
        value: 'shipment_summary_lot.returnable_rh_toz',
        align: 'right'
      },
      {
        text: 'Gross Lbs',
        value: 'shipment_summary_lot.gross_lbs',
        align: 'right'
      },
      {
        text: 'Net Wet Lbs',
        value: 'shipment_summary_lot.net_wet_lbs',
        align: 'right'
      },
      {
        text: 'Scrap Lbs',
        value: 'shipment_summary_lot.scrap_lbs',
        align: 'right'
      },
      {
        text: 'Net Dry Lbs',
        value: 'shipment_summary_lot.net_dry_lbs',
        align: 'right'
      },
      {
        text: 'Carbon %',
        value: 'shipment_summary_lot.carbon_percent',
        align: 'right'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapGetters('lotStore', ['lotStatuses', 'lotTypes']),
    // aggregate returned report gross lbs, net wet lbs, and net dry lbs into an object called totals
    reportTotals () {
      let totalCarbonLbs = 0
      const totals = {
        intake_total: 0,
        contained_pt_toz: 0,
        contained_pd_toz: 0,
        contained_rh_toz: 0,
        returnable_pt_toz: 0,
        returnable_pd_toz: 0,
        returnable_rh_toz: 0,
        gross_lbs: 0,
        net_wet_lbs: 0,
        scrap_lbs: 0,
        net_dry_lbs: 0,
        carbon_percent: 0
      }
      this.report.forEach(row => {
        if (row.shipment_summary_lot !== null) {
          Object.keys(totals).forEach(k => {
            if (row.shipment_summary_lot[k]) {
              totals[k] = BigNumber(totals[k]).plus(row.shipment_summary_lot[k]).toFixed(3)
            }
          })
          totalCarbonLbs += row.shipment_summary_lot.carbon_percent * row.shipment_summary_lot.net_dry_lbs
        }

        if (row.intake_total) totals.intake_total += row.intake_total
      })
      if (totals.net_dry_lbs !== 0) {
        totals.carbon_percent = (totalCarbonLbs / totals.net_dry_lbs).toFixed(3)
      }
      return totals
    }
  },
  methods: {
    // fetch smelters from the API
    async fetchSmelters () {
      const response = await axios.get('/api/smelters/')
      this.smelters = response.data.results
    },
    // alter report request based on user input into the filter controls
    buildReportRequestQueryParams () {
      let queryParams = {}
      if (Array.isArray(this.selectedSmelters) && this.selectedSmelters.length) {
        queryParams['smelter__name__in'] = this.selectedSmelters.join()
      }
      if (Array.isArray(this.selectedLotStatuses) && this.selectedLotStatuses.length) {
        queryParams['status__in'] = this.selectedLotStatuses.join()
      }
      if (Array.isArray(this.selectedLotDesignations)) {
        queryParams['designation__in'] = this.selectedLotDesignations.join()
      }
      if (this.smelterReceivedAfter != null) {
        queryParams['shipment_summary_lot__shipment_summary__received_date__gte'] = this.smelterReceivedAfter
      }
      if (this.smelterReceivedBefore != null) {
        queryParams['shipment_summary_lot__shipment_summary__received_date__lte'] = this.smelterReceivedBefore
      }
      if (this.lotShippedBefore != null) {
        queryParams['shipment_summary_lot__shipment_summary__shipped_date__gte'] = this.lotShippedBefore
      }
      if (this.lotShippedAfter != null) {
        queryParams['shipment_summary_lot__shipment_summary__shipped_date__lte'] = this.lotShippedAfter
      }
      return queryParams
    },
    async fetchMaterialShippedToSmelterReport () {
      this.report = null
      const response = await axios.get('/api/smelter_shipment_history_report/', {
        params: this.buildReportRequestQueryParams()
      })
      this.report = response.data.map(row => {
        ;[
          'contained_pt_toz',
          'contained_pd_toz',
          'contained_rh_toz',
          'returnable_pt_toz',
          'returnable_pd_toz',
          'returnable_rh_toz'
        ].forEach(k => {
          if (row.shipment_summary_lot?.[k]) {
            row.shipment_summary_lot[k] = this.trimDecimals((row.shipment_summary_lot[k]))
          }
        })
        try {
          row.shipment_summary_lot.carbon_percent = Number(row.shipment_summary_lot.carbon_percent).toFixed(3)
        } catch {
          row.shipment_summary_lot.carbon_percent = 'N/A'
        }
        if (row.shipment_summary_lot?.shipped_date) {
          row.shipment_summary_lot.shipped_date = this.formatDate(row.shipment_summary_lot.shipped_date)
        }
        return row
      })
    },
    // remove a lot and its weights from the report data
    removeLotFromReport (id) {
      this.report.splice(this.report.findIndex(item => item.id === id), 1)
    },
    headerText () {
      return this.headers.reduce((c, h) => {
        if (h.value !== 'actions') c.push(h.text)
        return c
      }, [])
    },
    // Create and download CSV file from report
    reportToCsv () {
      // CSV content-type string
      let csvContent = 'data:text/csv;charset=utf-8,'
      // lot rows
      let rows = this.report.map(lot => {
        if (lot.shipment_summary_lot) {
          return [
            lot.name.replace(',', ''),
            lot.shipment_summary_lot.smelter,
            lot.shipment_summary_lot.shipped_date,
            lot.intake_total,
            lot.shipment_summary_lot.contained_pt_toz,
            lot.shipment_summary_lot.contained_pd_toz,
            lot.shipment_summary_lot.contained_rh_toz,
            lot.shipment_summary_lot.returnable_pt_toz,
            lot.shipment_summary_lot.returnable_pd_toz,
            lot.shipment_summary_lot.returnable_rh_toz,
            lot.shipment_summary_lot.gross_lbs,
            lot.shipment_summary_lot.net_wet_lbs,
            lot.shipment_summary_lot.scrap_lbs,
            lot.shipment_summary_lot.net_dry_lbs,
            lot.shipment_summary_lot.carbon_percent
          ].join()
        }
        return [
          lot.name.replace(',', ''),
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ].join()
      })
      // prepend the header.text unless the header.value is actions
      rows.unshift(this.headerText())
      // totals row
      rows.push([
        'Totals',
        '',
        '',
        this.reportTotals.intake_total,
        this.reportTotals.contained_pt_toz,
        this.reportTotals.contained_pd_toz,
        this.reportTotals.contained_rh_toz,
        this.reportTotals.returnable_pt_toz,
        this.reportTotals.returnable_pd_toz,
        this.reportTotals.returnable_rh_toz,
        this.reportTotals.gross_lbs,
        this.reportTotals.net_wet_lbs,
        this.reportTotals.scrap_lbs,
        this.reportTotals.net_dry_lbs,
        this.reportTotals.carbon_percent
      ].join())
      // append lot and totals rows to CSV string
      let encodedUri = encodeURI(csvContent + rows.join('\n'))

      // create a link tag to enable naming of the downloaded CSV
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)

      // create date string for use in csv filename
      const date = new Date()
      const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split('T')[0]

      // append name to link attributes
      // e.g. smelter-shipment-history-report-YYYY-MM-DD-HH_MM_SS
      link.setAttribute('download', `SmelterShipmentHistoryReport-${dateString}.csv`)
      // download CSV by clicking the link
      link.click()
    },
    trimDecimals (text) {
      return this.$options.filters.trimDecimals(text)
    },
    addCommas (text) {
      return this.$options.filters.addCommas(text)
    },
    formatDate (text) {
      return this.$options.filters.formatDate(text)
    }
  },
  mounted () {
    this.fetchSmelters()
  }
}
</script>
