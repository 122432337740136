var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('edit-dialog',{attrs:{"value":_vm.value,"headline-text":"Edit Final Report","inputs-valid":!invalid},on:{"accept":_vm.patchReport,"input":function($event){return _vm.$emit('input', $event)}}},[_c('h2',[_vm._v("Connected Lots")]),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('search-autocomplete',{attrs:{"params":{ page_size: 0, fields: 'id,url,name', smelter: _vm.report.smelter_id },"label":"Lookup and Add Lot","api-path":"/api/lots/","format-entry":function (x) { return Object.assign({ friendlyName: x.name }, x); }},on:{"input":_vm.addLot},model:{value:(_vm.lookUpLot),callback:function ($$v) {_vm.lookUpLot=$$v},expression:"lookUpLot"}}),_c('p',[_c('strong',[_vm._v(" There must be at least one Lot. Lots already attached to a Profit Estimations will not appear. ")])])],1),_c('v-col',{attrs:{"md":"6"}},_vm._l((_vm.lots),function(lot){return _c('v-chip',{key:lot.url,attrs:{"close":""},on:{"click:close":function($event){return _vm.removeLot(lot)}}},[_vm._v(" "+_vm._s(lot.name)+" ")])}),1)],1),_c('h2',[_vm._v("Smelter")]),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"label":"Smelter Select","items":_vm.$store.getters['smelterStore/smelters'],"item-value":"url","item-text":"name"},on:{"input":_vm.useSmelterRates},model:{value:(_vm.smelterUrl),callback:function ($$v) {_vm.smelterUrl=$$v},expression:"smelterUrl"}}),_c('p',[_c('strong',[_vm._v(" Selecting a smelter will update return rates and treatment charge ")])])],1),_c('v-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Treatment Charge Rate","prepend-inner-icon":"mdi-currency-usd","hint":_vm.treatmentChargeRate + ' * ' + _vm.report.total_net_weight + '= $' + Math.round(_vm.treatmentChargeRate * _vm.report.total_net_weight),"error-messages":errors},model:{value:(_vm.treatmentChargeRate),callback:function ($$v) {_vm.treatmentChargeRate=$$v},expression:"treatmentChargeRate"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Platinum Return Rate","error-messages":errors},model:{value:(_vm.ptReturnRate),callback:function ($$v) {_vm.ptReturnRate=$$v},expression:"ptReturnRate"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Palladium Return Rate","error-messages":errors},model:{value:(_vm.pdReturnRate),callback:function ($$v) {_vm.pdReturnRate=$$v},expression:"pdReturnRate"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Rhodium Return Rate","error-messages":errors},model:{value:(_vm.rhReturnRate),callback:function ($$v) {_vm.rhReturnRate=$$v},expression:"rhReturnRate"}})]}}],null,true)})],1)],1),_c('h2',[_vm._v("Charges")]),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Total Investment","prepend-inner-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.totalInvestment),callback:function ($$v) {_vm.totalInvestment=$$v},expression:"totalInvestment"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Lab Assay Charge","prepend-inner-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.labAssayCharge),callback:function ($$v) {_vm.labAssayCharge=$$v},expression:"labAssayCharge"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Refining Charge","prepend-inner-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.refiningCharge),callback:function ($$v) {_vm.refiningCharge=$$v},expression:"refiningCharge"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Small Lot Charge","prepend-inner-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.smallLotCharge),callback:function ($$v) {_vm.smallLotCharge=$$v},expression:"smallLotCharge"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Freight Charge","prepend-inner-icon":"mdi-currency-usd","hint":"Charge that represents the shipping costs.","error-messages":errors},model:{value:(_vm.freightCharge),callback:function ($$v) {_vm.freightCharge=$$v},expression:"freightCharge"}})]}}],null,true)})],1)],1),_c('h2',[_vm._v("Dates")]),_c('v-row',[_c('v-col',[_c('date-input',{attrs:{"label":"Smelter ETA"},model:{value:(_vm.estimatedArrival),callback:function ($$v) {_vm.estimatedArrival=$$v},expression:"estimatedArrival"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Notes","hint":"Add any notes you would like to appear on the profit estimation."},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }