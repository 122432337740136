<!--
This form is responsible for making all of the information in a brokered settlement and the computed fields make sense.
It is not responsible for updating the database or retrieving data
-->

<template>
  <unauthorized
    :authorized="$store.getters.can('view-brokered-settlements')"
    message="You are not authorized to view brokered settlements"
  >
    <brokered-settlement-skeleton
      v-if="loading"
    />
    <ValidationObserver
      v-else
      v-slot="{ invalid, reset }"
    >
      <v-form
        :disabled="bs.status && bs.status === 'FI'"
        :value="invalid"
        @submit.prevent="save"
        @reset.prevent="reset"
      >
        <v-container>
          <url-breadcrumbs
            v-if="breadcrumbs"
            :items="breadcrumbs"
          />
          <v-alert
            v-if="showAlert"
            dark
            dense
            :color="showAlert.color"
          >
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ showAlert.text }}
          </v-alert>
          <h1>Brokered Settlement</h1>
          <help-dialog
            headline-text="Yard Terms"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="!Object.keys(bt).length || !$store.getters.can('edit-brokered-settlements')"
                x-small
                @click="on"
              >
                show yard terms
              </v-btn>
            </template>
            <object-dump-list
              title="Yard Terms"
              v-model="bt"
              :exclude="['id', 'url', 'yard']"
            />
          </help-dialog>
          <b-s-toolbar-form
            :bs="bs"
            @delete="destroy"
            @finalize="finalize"
          />
          <b-s-properties-form
            :bs="bs"
            :yard-choices="config.yardChoices"
            @setProp="setProp($event)"
          />
          <b-s-outright-form
            :bs="bs"
            @setProp="setProp($event)"
          />
          <b-s-metals-form
            :bs="bs"
            :bt="bt"
            :smelter="smelter"
            :allocations="allocations"
            :config="config"
            :toz-to-allocate="tozToAllocate"
            @setProp="setProp($event)"
          />
          <b-s-fees-form
            :bs="bs"
            :config="config"
            @setProp="setProp($event)"
          />
          <b-s-due-form
            :bs="bs"
            :config="config"
            @setProp="setProp($event)"
          />
          <b-s-averages-due
            :bs="bs"
            :config="config"
            @setProp="setProp($event)"
          />
          <h6 class="text-h6 mb-4">
            Notes
          </h6>
          <v-row>
            <v-col>
              <v-textarea
                label="These notes will appear on the final report"
                :value="bs.notes"
                @input="setBsProperty('notes', $event)"
              />
            </v-col>
          </v-row>
          <v-btn
            :disabled="!unsavedChanges || invalid"
            fixed
            bottom
            right
            fab
            color="primary"
            type="submit"
          >
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-container>
      </v-form>
    </ValidationObserver>
  </unauthorized>
</template>

<script>
import BSPropertiesForm from '@/views/Lots/components/Brokered/components/BSPropertiesForm'
import BSFeesForm from '@/views/Lots/components/Brokered/components/BSFeesForm'
import BSDueForm from '@/views/Lots/components/Brokered/components/BSDueForm'
import BSAveragesDue from '@/views/Lots/components/Brokered/components/BSAveragesForm'
import BSOutrightForm from '@/views/Lots/components/Brokered/components/BSOutrightForm'
import BSMetalsForm from '@/views/Lots/components/Brokered/components/BSMetalsForm'
import { BigNumber } from 'bignumber.js'
import moment from 'moment'
import ObjectDumpList from '@/base/components/ObjectDumpList'
import BSToolbarForm from '@/views/Lots/components/Brokered/components/BSToolbarForm'
import BrokeredSettlementSkeleton from '@/views/Lots/components/Brokered/components/BrokeredSettlementSkeleton'

export default {
  name: 'BrokeredSettlementForm',
  components: { BrokeredSettlementSkeleton, BSToolbarForm, ObjectDumpList, BSMetalsForm, BSOutrightForm, BSAveragesDue, BSDueForm, BSFeesForm, BSPropertiesForm },
  data: () => {
    return ({
      loading: true,
      breadcrumbs: [],
      unsavedChanges: false,
      allocations: [],
      smelter: Object(),
      bs: Object(), // Private Brokered Settlement Data
      bsChanged: Object(),
      tozToAllocate: [0, 0, 0],
      world: null,
      defaultConfig: {
        // Prevent values from being calculated
        yardChoices: []
      },
      configOverride: Object(),
      calcMixins: [
        'calcTreatmentFeeMixin',
        'calcFinanceCharge',
        'calcNetValue',
        'calcSettlementAmount',
        'calcCatalystAverage',
        'calcAvgPerCat',
        'calcFreightCharge'
      ]
    })
  },
  computed: {
    bt () {
      return this.bs?.yard?.brokerageterm || Object()
    },
    showAlert () {
      if (this.bs.status && this.bs.status === 'FI') {
        return { color: 'primary', text: 'This settlement is finalized and no longer editable.' }
      } else if (!Object.keys(this.bt).length) {
        return { color: 'accent', text: 'No brokered terms: Add yard terms and reopen this form.' }
      } else if (this.unsavedChanges) {
        return { color: 'primary', text: 'You have unsaved changes.' }
      }
      return ''
    },
    config: {
      get () {
        return Object.assign({}, this.defaultConfig, this.configOverride)
      },
      /**
       * requires a key value pair for setting the config served as an array
       * @param configSet
       */
      set (configSet) {
        if (Array.isArray(configSet) && configSet.length === 2) {
          this.configOverride = Object.assign({}, this.configOverride, { [configSet[0]]: configSet[1] })
        }
      }
    }
  },
  methods: {
    calcTreatmentFeeMixin (updatedProp) {
      if (this.bs.treatment_fee_manual) return
      if (!['received_lbs_total'].includes(updatedProp)) return
      if (!this.bt.treatment_charge) return
      const fee = BigNumber(this.bt.treatment_charge)
        .multipliedBy(this.bs.received_lbs_total)
        .toFixed(2)
      this.setBsProperty('treatment_fee', fee)
    },
    /**
     * Calculates our Advanced Payment Financing Fee
     * For every day before 115 days we charge 1 day of prorated 5% apr
     * dateReceived + 115 = returnDate (returnDate - expPaymentDate) * (netValue * 5% / 360)
     * @param updatedProp
     */
    calcFinanceCharge (updatedProp) {
      if (this.bs.finance_charge_manual) return
      if (!['expected_pay_date', 'net_value'].includes(updatedProp)) return

      // Can't calculate 'days' if there are no dates set
      let days = 0
      if (this.config.receivedDate && this.bs.expected_pay_date) {
        // return date is received + 115 days
        const returnDate = moment(this.config.receivedDate).add(115, 'days')
        const payDate = moment(this.bs.expected_pay_date)
        days = returnDate.diff(payDate, 'days')
        if (days <= 0) days = 0
        this.$store.commit('setSnackbarInfo', `Calculated ${days} Day(s) Financed`)
      } else {
        this.$store.commit('setSnackbarWarning', `Missing required date to calculate Finance Charge`)
      }
      const fee = BigNumber(this.bs.net_value || 0)
        .multipliedBy(this.bt.finance_charge_percent / 100) // year interest at 5%
        .dividedBy(360) // day's worth of interest
        .multipliedBy(days)
        .toFixed(2)
      this.setBsProperty('finance_charge', fee)
    },
    calcNetValue (updatedProp) {
      if (!['gross_value', 'treatment_fee', 'freight_charge', 'small_lot_fee', 'additional_fees'].includes(updatedProp)) return
      const fee = BigNumber(this.bs.gross_value || 0)
        .minus(this.bs.treatment_fee || 0)
        .minus(this.bs.freight_charge || 0)
        .minus(this.bs.small_lot_fee || 0)
        .minus(this.bs.additional_fees.reduce((pv, cv) => pv + +cv.other_fee_value, 0))
        .toFixed(2)
      this.setBsProperty('net_value', fee)
    },
    calcSettlementAmount (updatedProp) {
      if (!['unpaid_units', 'net_value', 'advances', 'finance_charge'].includes(updatedProp)) return
      const fee = BigNumber(this.bs.net_value || 0)
        .plus(this.bs.unpaid_units || 0) // Via CODIE BROOKS unpaid units to be added to settlement amount but not net value
        .minus(this.bs.advances || 0)
        .minus(this.bs.finance_charge || 0)
        .toFixed(2)
      this.setBsProperty('amount_due', fee)
    },
    calcCatalystAverage (updatedProp) {
      if (!['net_value', 'received_lbs_total'].includes(updatedProp)) return
      let avg = 0
      const divisor = this.bs.received_lbs_total
      if (divisor && +divisor !== 0) {
        avg = BigNumber(this.bs.net_value || 0)
          .dividedBy(divisor)
          .toFixed(2)
      }
      if (avg < 0) avg = '0.00'
      this.setBsProperty('average_per_lb', avg)
    },
    calcAvgPerCat (updatedProp) {
      if (this.bs.avg_per_cat_manual) return
      if (!['net_value', 'converters_processed'].includes(updatedProp)) return
      let avg = 0
      const divisor = this.bs.converters_processed
      if (divisor && +divisor !== 0) {
        avg = BigNumber(this.bs.net_value || 0)
          .dividedBy(this.bs.converters_processed || 0)
          .toFixed(2)
      }
      if (avg < 0) avg = '0.00'
      this.setBsProperty('converter_average_price', avg)
    },
    calcFreightCharge (updatedProp) {
      if (this.bs.freight_charge_manual) return
      if (this.bt.freight_responsibility !== 'VE') return // Must be a Vendor freight responsibility
      if (!['received_lbs_total', 'freight_charge', 'freight_charge_manual'].includes(updatedProp)) return
      if (!this.bs.received_lbs_total) return
      if (!(this.bt.handling_fee)) return
      const fee = this.bs.received_lbs_total >= 2000 ? 0 : this.bt.handling_fee
      this.setBsProperty('small_lot_fee', fee)
    },
    /**
     * Shorthand setBsProperty Interface
     * @param propSet
     */
    setProp (propSet) {
      this.setBsProperty(...propSet)
    },
    /**
     * Assigns a property to BrokeredSettlement based on a prop value
     * Marks the property as modified, then applies all calculation mixins
     * @param prop
     * @param value
     */
    setBsProperty (prop, value) {
      this.setChanged(prop, this.bs[prop], value)
      this.bs = Object.assign({}, this.bs, { [prop]: value })
      // Mixins
      for (const index in this.calcMixins) {
        this[this.calcMixins[index]](prop)
      }
    },
    setChanged (prop, from, to) {
      this.unsavedChanges = true
      this.bsChanged[prop] = to
    },
    finalize () {
      alert('sorry form finalize is not implemented')
    },
    destroy () {
      alert('sorry form destroy is not implemented')
    },
    save () {
      if (!this.$store.getters.can('edit-brokered-settlements')) {
        this.$emit('Unauthorized')
      }
      alert('sorry form save is not implemented')
      // clear the unsaved changes and the bs changed
      this.unsavedChanges = false
      this.bsChanged = Object()
    }
  }
}
</script>
