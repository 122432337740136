<template>
  <unauthorized
    :authorized="$store.getters.can('create-lots')"
    message="Unauthorized to create Shipment Summaries"
  >
    <base-title title="Create Shipment Summary" />
    <v-sheet>
      <v-stepper
        v-model="step"
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            Smelter &amp; Lots
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 2"
            step="2"
          >
            Shipment Summary
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 3"
            step="3"
          >
            Shipment Summary Lots
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 4"
            step="4"
          >
            Confirm
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-btn
        class="mt-2"
        text
        v-if="step !== 1 && inputType === 'manual'"
        @click="determineRegression"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
        {{ step === 2 ? 'Back to Smelter & Lots Selection' : (step === 3 ? 'Back to Shipment Summary Tab' : 'Back To Shipment Summary Lots Tab') }}
      </v-btn>
      <v-btn
        v-if="(step !== 1 && inputType === 'manual') || (step === 4 && inputType === 'import')"
        small
        text
        color="accent"
        right
        absolute
        class="mt-2"
        @click="cancelAll"
      >
        <v-icon small>
          mdi-close
        </v-icon>
        Cancel
      </v-btn>
      <v-window v-model="step">
        <v-window-item :value="1">
          <write-smelter-lot
            :reset-forms="resetForms"
            :lot="lot"
            @incStep="step++"
          />
        </v-window-item>
        <v-window-item :value="2">
          <write-summary
            :reset-forms="resetForms"
            @skipLots="skipLots"
            @incStep="step++"
            @goBack="step--"
          />
        </v-window-item>
        <v-window-item :value="3">
          <write-summary-lots
            :lots="lots"
            :deleted-lots="deletedLots"
            @decStep="step--"
            @incStep="step++"
          />
        </v-window-item>
        <v-window-item :value="4">
          <confirm-summary
            class="mt-8"
            :shipment-summary="shipmentSummary"
            @updateRoute="updateRoute"
          />
        </v-window-item>
      </v-window>
    </v-sheet>
  </unauthorized>
</template>

<script>
import WriteSmelterLot from './WriteSmelterLot'
import WriteSummary from './WriteSummary'
import WriteSummaryLots from './WriteSummaryLots'
import ConfirmSummary from './ConfirmSummary'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateShipmentSummary',
  props: {
    lot: { type: Object, required: false, default: null }
  },
  components: {
    WriteSmelterLot,
    WriteSummary,
    WriteSummaryLots,
    ConfirmSummary
  },
  data: () => ({
    step: 1,
    deletedLots: false,
    resetForms: false
  }),
  methods: {
    ...mapActions({
      deleteShipmentSummary: 'shipmentSummaryStore/deleteShipmentSummary',
      deleteSSL: 'shipmentSummaryStore/deleteSSL'
    }),
    skipLots () {
      this.step += 2
    },
    cancelAll () {
      if (this.inputType === 'manual') this.resetForms = true
      this.deleteShipmentSummary(this.shipmentSummary).then(() => {
        this.step = 1
        this.resetForms = false
      })
    },
    // if creating from lot page, refresh; else route to new shipment summary page
    updateRoute (summaryId) {
      if (this.lot) this.$emit('updateRoute')
      else this.$router.push({ name: 'shipment-summary', params: { id: summaryId } })
    },
    determineRegression () {
      if (this.step === 2) this.step--
      else if (this.step === 3) {
        this.deleteShipmentSummary(this.shipmentSummary).then(() => {
          this.step--
          this.deletedLots = true
        })
      } else if (this.step === 4) {
        this.deleteSSL(this.shipmentSummary).then(() => {
          this.step--
          this.deletedLots = true
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      shipmentSummary: 'shipmentSummaryStore/shipmentSummary',
      lots: 'shipmentSummaryStore/getLots',
      inputType: 'shipmentSummaryStore/getInputType'
    })
  }
}
</script>
