<template>
  <v-simple-table
    dense
  >
    <tbody>
      <tr>
        <td class="text-right font-weight-bold">
          Lot #:
        </td>
        <td>
          {{ report.lot_number.replaceAll(',', ' ') | filterNA }}
        </td>
        <td class="text-right font-weight-bold">
          Client:
        </td>
        <td>
          {{ report.client | filterNA }}
        </td>
        <td class="text-right font-weight-bold">
          Ship Date:
        </td>
        <td>
          {{ report.shipment_date.replaceAll(',', ' ') | filterNA }}
        </td>
      </tr>
      <tr>
        <td class="text-right font-weight-bold">
          Smelter Lot #:
        </td>
        <td>
          {{ report.smelter_lot_number.replaceAll(',', ' ') | filterNA }}
        </td>
        <td class="text-right font-weight-bold">
          Smelter:
        </td>
        <td>
          {{ report.smelter.replaceAll(',', ' ') | filterNA }}
        </td>
        <td class="text-right font-weight-bold">
          Smelter ETA:
        </td>
        <td>
          {{ report.profit_estimation.estimated_arrival_date | formatDate | filterNA }}
        </td>
      </tr>
      <tr>
        <td class="text-right font-weight-bold">
          Lot Type:
        </td>
        <td>
          {{ report.lot_type.replaceAll(',', ' ') | filterNA }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>

export default {
  name: 'ProfitEstimationOverview',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
