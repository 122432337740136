<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th>Date</th>
        <th>Hedge</th>
        <th>Pt toz</th>
        <th>Price</th>
        <th>Total</th>
        <th>Pd toz</th>
        <th>Price</th>
        <th>Total</th>
        <th>Rh toz</th>
        <th>Price</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody
      class="text-right"
      v-for="(allocation, index) in report.metal_allocations"
      :key="index"
    >
      <tr>
        <td style="padding:0">
          {{ allocation.created_at }}
        </td>
        <td style="padding:0">
          <router-link-id
            class="d-print-none"
            :id="Number(allocation.hedge)"
            name="hedge"
          />
          <span class="d-none d-print-block">
            {{ allocation.hedge }}
          </span>
        </td>
        <td style="padding:0">
          {{ allocation.platinum_ctoz | toFixed(3) }}
        </td>
        <td style="padding:0">
          {{ allocation.platinum_rate | currencyUSD }}
        </td>
        <td style="padding:0">
          {{ allocation.platinum_ctoz * allocation.platinum_rate | currencyUSD }}
        </td>
        <td style="padding:0">
          {{ allocation.palladium_ctoz | toFixed(3) }}
        </td>
        <td style="padding:0">
          {{ allocation.palladium_rate | currencyUSD }}
        </td>
        <td style="padding:0">
          {{ allocation.palladium_ctoz * allocation.palladium_rate | currencyUSD }}
        </td>
        <td style="padding:0">
          {{ allocation.rhodium_ctoz | toFixed(3) }}
        </td>
        <td style="padding:0">
          {{ allocation.rhodium_rate | currencyUSD }}
        </td>
        <td style="padding:0">
          {{ allocation.rhodium_ctoz * allocation.rhodium_rate | currencyUSD }}
        </td>
      </tr>
    </tbody>
    <tfoot class="text-right">
      <tr>
        <td style="padding:0">
          Totals
        </td>
        <td style="padding:0" />
        <td style="padding:0">
          {{ footer.platinum_ctoz | toFixed(3) }}
        </td>
        <td style="padding:0" />
        <td style="padding:0">
          {{ footer.platinum_rate_accum | currencyUSD }}
        </td>
        <td style="padding:0">
          {{ footer.palladium_ctoz | toFixed(3) }}
        </td>
        <td style="padding:0" />
        <td style="padding:0">
          {{ footer.palladium_rate_accum | currencyUSD }}
        </td>
        <td style="padding:0">
          {{ footer.rhodium_ctoz | toFixed(3) }}
        </td>
        <td style="padding:0" />
        <td style="padding:0">
          {{ footer.rhodium_rate_accum | currencyUSD }}
        </td>
      </tr>
    </tfoot>
  </v-simple-table>
</template>

<script>

export default {
  name: 'DetailedReportAllocations',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  computed: {
    footer () {
      let platinumCtozTotal = 0; let palladiumCtozTotal = 0; let rhodiumCtozTotal = 0
      let platinumRateAccum = 0; let palladiumRateAccum = 0; let rhodiumRateAccum = 0
      this.report.metal_allocations.forEach(a => {
        platinumCtozTotal += a.platinum_ctoz
        platinumRateAccum += a.platinum_ctoz * a.platinum_rate
        palladiumCtozTotal += a.palladium_ctoz
        palladiumRateAccum += a.palladium_ctoz * a.palladium_rate
        rhodiumCtozTotal += a.rhodium_ctoz
        rhodiumRateAccum += a.rhodium_ctoz * a.rhodium_rate
      })
      return {
        platinum_ctoz: platinumCtozTotal,
        platinum_rate_accum: platinumRateAccum,
        platinum_rate: platinumRateAccum / platinumCtozTotal,
        palladium_ctoz: palladiumCtozTotal,
        palladium_rate_accum: palladiumRateAccum,
        palladium_rate: palladiumRateAccum / palladiumCtozTotal,
        rhodium_ctoz: rhodiumCtozTotal,
        rhodium_rate_accum: rhodiumRateAccum,
        rhodium_rate: rhodiumRateAccum / rhodiumCtozTotal
      }
    }
  }
}
</script>
