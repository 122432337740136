<template>
  <ValidationObserver
    v-slot="{ invalid }"
  >
    <edit-dialog
      :value="value"
      headline-text="Edit Final Report"
      :inputs-valid="!invalid"
      @accept="patchReport"
      @input="$emit('input', $event)"
    >
      <h2>Connected Lots</h2>
      <v-row>
        <v-col md="6">
          <search-autocomplete
            v-model="lookUpLot"
            :params="{ page_size: 0, fields: 'id,url,name', smelter: report.smelter_id }"
            label="Lookup and Add Lot"
            api-path="/api/lots/"
            :format-entry="x => Object.assign({ friendlyName: x.name }, x)"
            @input="addLot"
          />
          <p>
            <strong>
              There must be at least one Lot. Lots already attached to a Profit Estimations will not appear.
            </strong>
          </p>
        </v-col>
        <v-col md="6">
          <v-chip
            v-for="lot in lots"
            :key="lot.url"
            close
            @click:close="removeLot(lot)"
          >
            {{ lot.name }}
          </v-chip>
        </v-col>
      </v-row>
      <h2>Smelter</h2>
      <v-row>
        <v-col
          md="6"
        >
          <v-select
            label="Smelter Select"
            v-model="smelterUrl"
            :items="$store.getters['smelterStore/smelters']"
            item-value="url"
            item-text="name"
            @input="useSmelterRates"
          />
          <p>
            <strong>
              Selecting a smelter will update return rates and treatment charge
            </strong>
          </p>
        </v-col>
        <v-col
          md="6"
        >
          <ValidationProvider
            rules="required|min_value:0|decimal:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="treatmentChargeRate"
              type="number"
              label="Treatment Charge Rate"
              prepend-inner-icon="mdi-currency-usd"
              :hint="treatmentChargeRate + ' * ' + report.total_net_weight + '= $' + Math.round(treatmentChargeRate * report.total_net_weight)"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            rules="required|min_value:0|decimal:4"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="ptReturnRate"
              type="number"
              label="Platinum Return Rate"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            rules="required|min_value:0|decimal:4"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="pdReturnRate"
              type="number"
              label="Palladium Return Rate"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            rules="required|min_value:0|decimal:4"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="rhReturnRate"
              type="number"
              label="Rhodium Return Rate"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <h2>Charges</h2>
      <v-row>
        <v-col md="6">
          <ValidationProvider
            rules="required|min_value:0|decimal:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="totalInvestment"
              type="number"
              label="Total Investment"
              prepend-inner-icon="mdi-currency-usd"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            rules="required|min_value:0|decimal:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="labAssayCharge"
              type="number"
              label="Lab Assay Charge"
              prepend-inner-icon="mdi-currency-usd"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            rules="required|min_value:0|decimal:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="refiningCharge"
              type="number"
              label="Refining Charge"
              prepend-inner-icon="mdi-currency-usd"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col md="6">
          <ValidationProvider
            rules="required|min_value:0|decimal:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="smallLotCharge"
              type="number"
              label="Small Lot Charge"
              prepend-inner-icon="mdi-currency-usd"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            rules="required|min_value:0|decimal:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="freightCharge"
              type="number"
              label="Freight Charge"
              prepend-inner-icon="mdi-currency-usd"
              hint="Charge that represents the shipping costs."
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <h2>Dates</h2>
      <v-row>
        <v-col>
          <date-input
            v-model="estimatedArrival"
            label="Smelter ETA"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="notes"
            label="Notes"
            hint="Add any notes you would like to appear on the profit estimation."
          />
        </v-col>
      </v-row>
    </edit-dialog>
  </ValidationObserver>
</template>

<script>
import SearchAutocomplete from '@/base/components/SearchAutocomplete'

export default {
  name: 'ProfitEstimationEdit',
  components: { SearchAutocomplete },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    profitEstimation: {
      type: Object,
      required: true
    },
    report: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    smelterUrl: null,
    ptReturnRate: 0,
    pdReturnRate: 0,
    rhReturnRate: 0,
    treatmentChargeRate: 0,
    labAssayCharge: 0,
    refiningCharge: 0,
    smallLotCharge: 0,
    freightCharge: 0,
    totalInvestment: 0,
    estimatedArrival: null,
    lookUpLot: null,
    lots: [],
    notes: ''
  }),
  watch: {
    async value (opened) {
      if (opened) {
        await this.$store.dispatch('smelterStore/fetchSmelters')
        this.smelterUrl = this.profitEstimation.smelter
        this.totalInvestment = this.profitEstimation.total_investment
        this.treatmentChargeRate = this.profitEstimation.treatment_charge_rate
        this.labAssayCharge = this.profitEstimation.lab_assay_charge
        this.refiningCharge = this.profitEstimation.refining_charge
        this.smallLotCharge = this.profitEstimation.small_lot_charge
        this.freightCharge = this.profitEstimation.freight_charge
        this.ptReturnRate = this.profitEstimation.pt_return_rate
        this.pdReturnRate = this.profitEstimation.pd_return_rate
        this.rhReturnRate = this.profitEstimation.rh_return_rate
        this.estimatedArrival = this.profitEstimation.estimated_arrival_date
        this.notes = this.report.notes
        this.lots = this.report.lot_list
      }
    }
  },
  methods: {
    useSmelterRates (url) {
      const smelter = this.$store.getters['smelterStore/smelters'].find(s => s.url === url)
      this.treatmentChargeRate = Number(smelter.treatment_rate).toFixed(2)
      this.ptReturnRate = smelter.pt_return_rate
      this.pdReturnRate = smelter.pd_return_rate
      this.rhReturnRate = smelter.rh_return_rate
    },
    addLot (lot) {
      if (this.lots.findIndex(s => s.url === lot.url) !== -1) return
      if (lot) this.lots = [...this.lots, lot]
    },
    removeLot (lot) {
      const list = this.lots.filter(x => x.url !== lot.url)
      if (list.length > 0) {
        this.lots = list
      } else {
        this.$store.commit('setSnackbarWarning', 'There must be at least one lot attached.')
      }
    },
    patchReport () {
      const payload = {
        smelter: this.smelterUrl,
        total_investment: this.totalInvestment,
        treatment_charge_rate: this.treatmentChargeRate,
        lab_assay_charge: this.labAssayCharge,
        refining_charge: this.refiningCharge,
        small_lot_charge: this.smallLotCharge,
        freight_charge: this.freightCharge,
        pt_return_rate: this.ptReturnRate,
        pd_return_rate: this.pdReturnRate,
        rh_return_rate: this.rhReturnRate,
        estimated_arrival_date: this.estimatedArrival,
        notes: this.notes,
        lot_set: this.lots.map(lot => lot.url)
        // todo something to save the lots
      }
      this.$emit('report-updated', payload)
    }
  }
}
</script>
