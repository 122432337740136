<template>
  <v-simple-table dense>
    <tbody>
      <tr>
        <td>Total Gross Metals Value</td>
        <td><dollar-sign />{{ report.total_gross_metals_value | toFixed(2) | addCommas }}</td>
      </tr>
      <tr>
        <td>Treatment Charge</td>
        <td><dollar-sign />{{ report.treatment_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Lab/Assay Charge</td>
        <td><dollar-sign />{{ report.lab_assay_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Refining Charge</td>
        <td><dollar-sign />{{ report.refining_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Small Lot Charge</td>
        <td><dollar-sign />{{ report.small_lot_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>
          <info-tooltip class="d-print-none">
            Uses Total Charges if available else combines Treatment, Lab/Assay Charge, Refining Charge and Small Lot Charge.
          </info-tooltip>
          Total Charges
        </td>
        <td><dollar-sign />{{ report.total_charges | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Smelter Finance Charge</td>
        <td><dollar-sign />{{ report.finance_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Smelter Total Final Payment</td>
        <td><dollar-sign />{{ report.total_final_payment | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>LOC Finance Charge</td>
        <td><dollar-sign />{{ report.loc_finance_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Freight Charge</td>
        <td><dollar-sign />{{ report.freight_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>      <tr>
        <td>Total Revenue</td>
        <td><dollar-sign />{{ report.total_revenue | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'FinalReportValues',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
tbody tr {
  text-align: right;
}
tbody tr td:first-child {
  font-weight: bold;
}
tbody tr td:last-child {
  max-width: 50px;
}
</style>
