<template>
  <unauthorized
    :authorized="$store.getters.can('view-partial-report')"
    message="You are not authorized to view Lots Reports"
  >
    <v-container v-if="report === null">
      <v-skeleton-loader type="heading" />
    </v-container>
    <v-container
      v-else
    >
      <p class="d-print-none">
        For best printing quality, turn off headers/footers and set margins to None.
      </p>
      <v-alert
        v-if="editRequired"
        color="warning"
        outlined
        dense
      >
        <v-row>
          <v-col>
            <v-icon color="warning">
              mdi-alert
            </v-icon>
            {{ editRequired }}
          </v-col>
        </v-row>
      </v-alert>
      <v-card
        class="pa-2 mx-auto"
        flat
        max-width="1000px"
      >
        <v-row>
          <v-col
            class="text-center"
            cols="6"
            offset="3"
          >
            <v-img
              :src="config.logo"
              :alt="config.name"
              height="100px"
              contain
            />
          </v-col>
          <v-col
            class="text-right"
            cols="3"
          >
            <small>
              Report Date {{ report.report_date }}
            </small>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="title text-center"
          >
            Final Lot Report
          </v-col>
        </v-row>
        <!--Row 2-->
        <hr>
        <final-report-overview
          class="mb-10"
          :report="report"
        />
        <h3>Lot Shipment Summary</h3>
        <hr>
        <final-report-shipment
          class="mb-10"
          :report="report"
        />
        <h3>Smelter Return Summary</h3>
        <hr>
        <final-report-summary
          class="mb-10"
          :report="report"
        />
        <h3>Metals</h3>
        <hr>
        <div class="d-print-none">
          <h4>Allocations <info-tooltip>Does not appear when printed</info-tooltip></h4>
          <report-allocations
            class="mb-10"
            :report="report"
          />
        </div>
        <h4>Metal Summary</h4>
        <final-report-summary-metals
          :report="report"
          class="mb-10"
        />
        <h3>Charges and Values</h3>
        <hr>
        <final-report-values
          :report="report"
        />
        <h3>Profit Summary</h3>
        <hr>
        <final-report-profit
          class="mb-10"
          :report="report"
        />
        <h3>Notes</h3>
        <hr>
        <p
          v-if="this.report.notes"
          class="pa-4 pb-10"
        >
          {{ this.report.notes }}
        </p>
        <p v-else>
          <small>
            <i>Left Blank</i>
          </small>
        </p>
      </v-card>
      <final-report-edit
        v-if="report"
        v-model="edit"
        :report="report"
        @report-updated="patchReport"
      />
      <v-btn
        class="d-print-none"
        color="accent"
        fixed
        fab
        bottom
        right
        @click="edit = !edit"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-container>
  </unauthorized>
</template>

<script>
import FinalReportOverview from './components/FinalReportOverview'
import FinalReportShipment from './components/FinalReportShipment'
import FinalReportSummary from './components/FinalReportSummary'
import FinalReportSummaryMetals from './components/FinalReportSummaryMetals'
import FinalReportValues from './components/FinalReportValues'
import FinalReportProfit from './components/FinalReportProfit'
import FinalReportEdit from './components/FinalReportEdit'
import axios from 'axios'
import ReportAllocations from '@/views/FinanceReport/components/ReportAllocations'
import config from '@/../izzi_config'

export default {
  name: 'LotFinalReport',
  components: {
    ReportAllocations,
    FinalReportEdit,
    FinalReportValues,
    FinalReportProfit,
    FinalReportSummaryMetals,
    FinalReportSummary,
    FinalReportShipment,
    FinalReportOverview
  },
  data: () => ({
    config,
    loading: false,
    report: null,
    edit: false
  }),
  computed: {
    editRequired () {
      if (this.report.warnings && this.report.warnings.length > 0) {
        return this.report.warnings.join(' ')
      }
      return false
    },
    reportUrl () {
      const reportId = this.$route.params.id
      return `/api/final_reports/${reportId}/`
    }
  },
  methods: {
    async patchReport (payload) {
      this.loading = true
      await axios.patch(this.reportUrl, payload)
      await this.fetchReport()
      this.loading = false
    },
    async fetchReport () {
      this.loading = true
      const r = await axios.get(this.reportUrl + 'report/')
      this.report = r.data
      this.loading = false
    }
  },
  mounted () {
    this.fetchReport()
  }
}
</script>

<style scoped>
@media screen {
  div.divFooter {
    display: none;
  }
}
@media print {
  div.divFooter {
    position: fixed;
    bottom: 0;
  }
}
</style>
