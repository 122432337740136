import factories from './factories'
const { factory, fetchMixin, patchMixin, createMixin } = factories

const store = factory(
  {
    entityName: 'ProfitEstimation',
    urlTemplate: id => id ? `api/profit_estimation/${id}/` : `api/profit_estimation/`
  },
  createMixin,
  fetchMixin,
  patchMixin
)

export default store
