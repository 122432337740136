<template>
  <unauthorized
    :authorized="$store.getters.can('view-profit-estimation')"
    message="Unauthorized to view this page"
  >
    <base-title title="Profit Estimation List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <date-range-input
        :start-date="createdStartDate"
        :end-date="createdEndDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="createdStartDate = $event"
        @alterEndDate="createdEndDate = $event"
      />
      <v-text-field
        label="ID"
        v-model="id"
        type="number"
      />
      <v-text-field
        label="Notes"
        v-model="notes"
      />
      <v-text-field
        label="Lot ID"
        v-model="lot"
      />
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="profit-estimation"
        >
          <v-btn small>
            {{ item.id }}
          </v-btn>
        </router-link-id>
      </template>
      <template v-slot:item.lot_set__data="{ item }">
        <router-link
          v-for="lot in item.lot_set__data"
          :key="lot.id"
          :to="{ name: 'lot', params: { id: lot.id } }"
        >
          {{ lot.name }}
        </router-link>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | filterNA | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
  </unauthorized>
</template>

<script>
import axios from 'axios'
import paginationMixin from '@/base/filter/paginationMixin'
import chipsMixin from '@/base/filter/chipsMixin'
import ResultPagination from '@/base/components/ResultPagination'
import SearchFilters from '@/base/filter/SearchFilters'

export default {
  name: 'ProfitEstimationList',
  mixins: [chipsMixin, paginationMixin],
  components: {
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'profit-estimation-list',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Lots', value: 'lot_set__data' },
        { text: 'Notes', value: 'notes' },
        { text: 'Created', value: 'created_at' }
      ],
      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Notes', prop: 'notes', type: String, query: 'notes__icontains' },
        { name: 'createdStartDate', prop: 'createdStartDate', type: String, query: 'created_at__gte' },
        { name: 'createdEndDate', prop: 'createdEndDate', type: String, query: 'created_at__lte' },
        { name: 'Lot', prop: 'lot', type: String, query: 'lot' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      axios.get('/api/profit_estimation/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
