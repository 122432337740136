<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th>Hedge</th>
        <th>Platinum</th>
        <th>Palladium</th>
        <th>Rhodium</th>
      </tr>
    </thead>
    <tbody
      v-for="(allocation, index) in report.metal_allocations.filter(a => a.platinum_ctoz + a.palladium_ctoz + a.rhodium_ctoz)"
      :key="index"
    >
      <tr>
        <td>
          {{ allocation.hedge }}
          {{ allocation.hedge_name }}
        </td>
        <td>
          {{ allocation.platinum_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ allocation.platinum_rate | toFixed(2) | addCommas }}
          </small>
        </td>
        <td>
          {{ allocation.palladium_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ (allocation.palladium_rate) | toFixed(2) | addCommas }}
          </small>
        </td>
        <td>
          {{ allocation.rhodium_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ allocation.rhodium_rate | toFixed(2) | addCommas }}
          </small>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          Totals/Avg
        </td>
        <td>
          {{ footer.platinum_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ footer.platinum_rate | toFixed(2) | addCommas }}
          </small>
        </td>
        <td>
          {{ footer.palladium_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ footer.palladium_rate | toFixed(2) | addCommas }}
          </small>
        </td>
        <td>
          {{ footer.rhodium_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ footer.rhodium_rate | toFixed(2) | addCommas }}
          </small>
        </td>
      </tr>
    </tfoot>
  </v-simple-table>
</template>

<script>

export default {
  name: 'ReportAllocations',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  computed: {
    footer () {
      let platinumCtozTotal = 0; let palladiumCtozTotal = 0; let rhodiumCtozTotal = 0
      let platinumRateAccum = 0; let palladiumRateAccum = 0; let rhodiumRateAccum = 0
      this.report.metal_allocations.forEach(a => {
        platinumCtozTotal += a.platinum_ctoz
        platinumRateAccum += a.platinum_ctoz * a.platinum_rate
        palladiumCtozTotal += a.palladium_ctoz
        palladiumRateAccum += a.palladium_ctoz * a.palladium_rate
        rhodiumCtozTotal += a.rhodium_ctoz
        rhodiumRateAccum += a.rhodium_ctoz * a.rhodium_rate
      })
      return {
        platinum_ctoz: platinumCtozTotal,
        platinum_rate: platinumRateAccum / platinumCtozTotal,
        palladium_ctoz: palladiumCtozTotal,
        palladium_rate: palladiumRateAccum / palladiumCtozTotal,
        rhodium_ctoz: rhodiumCtozTotal,
        rhodium_rate: rhodiumRateAccum / rhodiumCtozTotal
      }
    }
  }
}
</script>
