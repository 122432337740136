var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.keys),function(key){return _c('v-row',{key:key.value},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|decimal:10|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"number","value":_vm.hedge[key.value + '_returnable'],"label":key.value + ' Toz',"error-messages":errors},on:{"input":function($event){return _vm.setProp(key.value,key.value + '_returnable', $event)}}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"2"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|decimal:2|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"number","value":_vm.hedge[key.value + '_return_rate'],"label":"Rate","append-outer-icon":"mdi-percent-outline","error-messages":errors},on:{"input":function($event){return _vm.setProp(key.value, key.value + '_return_rate', $event)}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|decimal:10|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","dense":"","type":"number","value":_vm.hedge[key.value + '_client_returnable'],"label":"Returnable Toz","error-messages":errors}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:9999999|decimal:3|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"number","value":_vm.hedge[key.value + '_mk'],"label":"Market Price","prepend-icon":"mdi-currency-usd","error-messages":errors},on:{"input":function($event){return _vm.setProp(key.value,key.value + '_mk', $event)}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|decimal:2|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"dense-filled",attrs:{"dense":"","readonly":"","type":"number","value":_vm.hedge[key.value + '_value'],"label":"Value","prepend-icon":"mdi-currency-usd","error-messages":errors,"hint":"(readonly) toz x price x rate"}})]}}],null,true)})],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }