<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th>Material Type</th>
        <th>Description</th>
        <th>Platinum</th>
        <th>Palladium</th>
        <th>Rhodium</th>
      </tr>
    </thead>
    <tbody
      v-for="({ material_type, platinum, palladium, rhodium }, index) in report.metal_summary"
      :key="index"
    >
      <tr>
        <td>
          {{ material_type }}
        </td>
        <td colspan="4">
          <v-simple-table>
            <tbody>
              <tr v-show="$store.getters.can('view-lot-report')">
                <td>Contained Assays</td>
                <td>{{ platinum.assay | toFixed(3) | addCommas }}</td>
                <td>{{ palladium.assay | toFixed(3) | addCommas }}</td>
                <td>{{ rhodium.assay | toFixed(3) | addCommas }}</td>
              </tr>
              <tr v-show="$store.getters.can('view-lot-report')">
                <td>Contained Metals</td>
                <td>{{ platinum.ctoz | toFixed(3) | addCommas }} toz</td>
                <td>{{ palladium.ctoz | toFixed(3) | addCommas }} toz</td>
                <td>{{ rhodium.ctoz | toFixed(3) | addCommas }} toz</td>
              </tr>
              <tr v-show="$store.getters.can('view-lot-report')">
                <td>Return Rates</td>
                <td>{{ (platinum.return_rate * 100).toFixed(2) }}%</td>
                <td>{{ (palladium.return_rate * 100).toFixed(2) }}%</td>
                <td>{{ (rhodium.return_rate * 100).toFixed(2) }}%</td>
              </tr>
              <tr>
                <td>Returnable Ounces</td>
                <td>{{ platinum.rtoz | toFixed(3) | addCommas }} toz</td>
                <td>{{ palladium.rtoz | toFixed(3) | addCommas }} toz</td>
                <td>{{ rhodium.rtoz | toFixed(3) | addCommas }} toz</td>
              </tr>
              <tr>
                <td>Avg. Hedge $/Oz</td>
                <td><dollar-sign />{{ platinum.avg_value | toFixed(2) | addCommas }}</td>
                <td><dollar-sign />{{ palladium.avg_value | toFixed(2) | addCommas }}</td>
                <td><dollar-sign />{{ rhodium.avg_value | toFixed(2) | addCommas }}</td>
              </tr>
              <tr>
                <td>Metals Value</td>
                <td><dollar-sign />{{ platinum.total_value | toFixed(2) | addCommas }}</td>
                <td><dollar-sign />{{ palladium.total_value | toFixed(2) | addCommas }}</td>
                <td><dollar-sign />{{ rhodium.total_value | toFixed(2) | addCommas }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>

export default {
  name: 'FinalReportSummaryMetals',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
