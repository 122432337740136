<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th>Material Type</th>
        <th>Gross Received</th>
        <th>Tare</th>
        <th>Net Wet Received</th>
        <th>Scrap</th>
        <th>Adj. Net Wet Received</th>
        <th>Percent Moisture</th>
        <th>Net Dry</th>
        <th>Carbon</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in report.smelter_summary"
        :key="index"
      >
        <td>{{ row.material_type }}</td>
        <td>{{ row.gross_received_lbs | toFixed(3) | addCommas }} lbs</td>
        <td>{{ row.tare_lbs | toFixed(3) | addCommas }} lbs</td>
        <td>{{ row.net_wet_received_lbs | toFixed(3) | addCommas }} lbs</td>
        <td>{{ row.scrap_lbs | toFixed(3) | addCommas }} lbs</td>
        <td>{{ row.adj_net_wet_received_lbs | toFixed(3) | addCommas }} lbs</td>
        <td>{{ row.percent_moisture | toFixed(3) }}<small>%</small></td>
        <td>{{ row.net_dry_lbs | toFixed(3) | addCommas }} lbs</td>
        <td>{{ row.carbon | toFixed(2) }}<small>%</small></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>

export default {
  name: 'FinalReportSummary',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
