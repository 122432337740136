<template>
  <v-card>
    <v-card-subtitle>
      <strong>Hedging Overview</strong>
    </v-card-subtitle>
    <v-row
      justify="center"
      v-if="lot.ppm_calculator"
    >
      <b class="subtitle-2"> PPM Calculation </b>
    </v-row>
    <v-row justify="center">
      <router-link
        v-if="!editingInfo"
        :to="{ name: 'ppm-calculation', params: { id: lot.ppm_calculator.id }}"
      >
        {{ lot.ppm_calculator.name }}
      </router-link>
      <base-button
        icon-bool
        color="info"
        icon="mdi-pencil"
        x-small
        @clicked="editingInfo = true"
        v-if="$store.getters.can('create-lots') && lot.status !== 'CO' && !editingInfo"
      />
    </v-row>
    <edit-ppm
      v-if="editingInfo"
      :ppm-calc="lot.ppm_calculator"
      :updating-lot="updatingLot"
      @cancel="editingInfo = false"
      @updateLot="update"
    />
    <v-simple-table>
      <hedge-oz-display :lot="lot" />
      <alloc-ave-values :allocations="allocations" />
    </v-simple-table>
    <allocations
      @addedAllocation="$emit('addedAllocation')"
      :allocations="allocations"
      :lot="lot"
      :hedges="hedges"
      :final-toz="finalToz"
    />
  </v-card>
</template>

<script>
import EditPpm from './EditPpm'
import HedgeOzDisplay from './HedgeOzDisplay'
import AllocAveValues from './AllocAveValues'
import Allocations from './Allocations'

export default {
  name: 'HedgingOverview',
  data: () => ({
    editingInfo: false
  }),
  props: {
    lot: { type: Object, required: true },
    allocations: { type: Array, required: true },
    finalToz: { type: Object, required: true },
    hedges: { type: Array, required: true },
    updatingLot: { type: Boolean, required: true }
  },
  components: {
    EditPpm,
    HedgeOzDisplay,
    AllocAveValues,
    Allocations
  },
  methods: {
    // Updates ppm calc
    update (body) {
      this.editingInfo = false
      this.$emit('updateLot', body)
    }
  }
}
</script>
