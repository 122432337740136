<template>
  <tbody>
    <tr>
      <td>Allocated Values</td>
      <td class="pt-color">
        ${{ totalAllocationValue('pt') | filterNA | toFixed(2) | addCommas }}
      </td>
      <td class="pd-color">
        ${{ totalAllocationValue('pd') | filterNA | toFixed(2) | addCommas }}
      </td>
      <td class="rh-color">
        ${{ totalAllocationValue('rh') | filterNA | toFixed(2) | addCommas }}
      </td>
    </tr>
    <tr>
      <td>Average Values</td>
      <td class="pt-color">
        ${{ averageAllocationValue('pt') | toFixed(2) | addCommas }}
      </td>
      <td class="pd-color">
        ${{ averageAllocationValue('pd') | toFixed(2) | addCommas }}
      </td>
      <td class="rh-color">
        ${{ averageAllocationValue('rh') | toFixed(2) | addCommas }}
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'AllocAveValues',
  props: { allocations: { type: Array, required: true } },
  methods: {
    /**
     * Get the correct market rate for the correct metal given a hedge
     * @param {String} metal
     * @param {Object} hedge
     */
    getHedgeMetalRate (metal, hedge) {
      metal = metal.toLowerCase()
      if (!hedge) return 0
      if (metal === 'pt') {
        return parseFloat(hedge.pt_rate_per_toz)
      } else if (metal === 'pd') {
        return parseFloat(hedge.pd_rate_per_toz)
      } else {
        return parseFloat(hedge.rh_rate_per_toz)
      }
    },
    /**
     * Get the total value of a metal based on its allocations
     * @param {String} metal - the metal to get the value for
     * @return {Float}
     */
    totalAllocationValue (metal) {
      metal = metal.toLowerCase()
      return this.allocations.filter(a => a.status !== 'HIS').reduce((accu, alloc) => {
        accu += parseFloat(alloc[metal]) * this.getHedgeMetalRate(metal, alloc.hedge)
        return accu
      }, 0)
    },
    /**
     * Get the average value of a metal based on its allocations
     * @param {String} metal - the metal to get the value for
     * @return {Float}
     */
    averageAllocationValue (metal) {
      if (this.allocations.length < 1) return 0
      const totalToz = this.allocations.filter(a => a.status !== 'HIS').reduce((accu, alloc) => {
        accu += parseFloat(alloc[metal])
        return accu
      }, 0)
      if (totalToz === 0) return 0
      return (this.totalAllocationValue(metal) / totalToz)
    }
  }
}
</script>
